<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      {{$t('generateReport_Marketing.localization_value.value')}}
    </template>
    <template slot="body">
      <div class="drop-file__content">
        <DropZone class="drop-zone-bg"
                  v-bind:class="{'ui-no-valid': valid}"
                  :parentFiles="File.data.files"
                  :multiple="true"
                  :maxCount="File.data.maxCountFiles - File.data.downloadFiles.length"
                  :maxSize="File.data.maxSizeFiles"
                  @changeImg="changeImg"
                  :accept="'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="drop-file__btn d-flex justify-content-between align-center">
        <div class="site-link site-link--alt drop-file__btn-i"
             @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </div>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="drop-file__btn-i"
            v-on:click.native="submitForm"
            :disabled="$store.getters.getConsolidationOrdersBtn"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/commonModals/Modal";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import {Files} from "@/components/globalModels/Files";

export default {
  name: "GenerateConsolidationMarketingPopup",
  components: {
    Modal,
    MainButton,
    DropZone,
  },

  data() {
    return {
      File: new Files({
        maxCountFiles: 1,
        maxSizeFiles: 5,
      }),
    }
  },

  methods: {
    submitForm() {
      if(!this.File.firstValidation()) return

      let reader = new FormData();
      this.File.data.files.map((item) => {
        if(!this._.has(item, 'maxCount') && !item.maxSizeError){
          reader.append("file", item.file);
        }
      })

      this.$store.dispatch('getExportMarketingReport', reader).then(response => {

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            const blob = new Blob([response.data], {type: `application/xlsx`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Export Marketing Report.xlsx`
            link.click()
            URL.revokeObjectURL(link.href)
            this.$emit('close')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    changeImg(files, maxSizeError) {
      this.File.setFiles(files)
      this.File.setFilesMaxSizeError(maxSizeError)
    },
  },

}
</script>

<style scoped>

</style>