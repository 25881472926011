<template>
  <div class="fragment">
    <div class="table-filter-wrap">
      <div class="site-table-wrap small-table"
           v-if="$store.getters.getConsolidationOrders.length && !$store.getters.getConsolidationOrdersLoading"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'consolidationOrders_Id',
            'consolidationOrders_unionCreated',
            'common_user',
            'consolidationOrders_shipped',
            'consolidationOrders_order',
            'consolidationOrders_customer',
            'consolidationOrders_status',
            'consolidationOrders_orderShipped',
            'fbm_inProgress',
            'fbm_completed',
            'common_manage',
            ])"></div>
        <table class="site-table mt-0" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th class="text-lg-center">{{$t('consolidationOrders_Id.localization_value.value')}}</th>
            <th>{{$t('consolidationOrders_unionCreated.localization_value.value')}}</th>
            <th>{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('consolidationOrders_shipped.localization_value.value')}}</th>
            <th>{{$t('consolidationOrders_order.localization_value.value')}}</th>
            <th>{{$t('consolidationOrders_customer.localization_value.value')}}</th>
            <th>{{$t('consolidationOrders_status.localization_value.value')}}</th>
            <th>{{$t('consolidationOrders_orderShipped.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getConsolidationOrders" :key="index">
            <td>
              #{{item.union_id}}
            </td>
            <td>
              <ValueHelper
                  :value="item.union_created_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <TableUserColumn
                  :item="item"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.union_received_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <router-link class="table-link btn-style" v-if="item.order_id"
                  :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.order_type).name + '/' + item.order_id">
                #{{item.order_id}}
              </router-link>
              <template v-else>—</template>
            </td>
            <td>
              <ValueHelper
                  :value="item.order_customer_name"
              />
            </td>
            <td>
              <template v-if="item.order_status === 'in_progress'">
                {{$t('fbm_inProgress.localization_value.value')}}
              </template>
              <template v-else-if="item.order_status === 'completed'">
                {{$t('fbm_completed.localization_value.value')}}
              </template>
              <template v-else>—</template>
            </td>
            <td>
              <ValueHelper
                  :value="item.order_delivery_date"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                             :key="$store.getters.getGlobalUpdateManagerButton">
                <template slot="item"
                          v-if="item.order_id && !(isCurrentUserRoleUser && isStatusComplete(item.status)) && !(isCurrentUserRoleUser && item.prepay === 1)"
                >
                  <LinkButton
                      :value="$t('common_edit.localization_value.value')"
                      :target="'_blank'"
                      :type="'edit'"
                      :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type).name + '/' + item.order_id"
                  />
                </template>
              </ManagerButton>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list pt-1">

            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getConsolidationOrders"
                 :key="index"
            >
              <ConsolidationOrdersTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  :selectedNow="selectedNow"
                  @removeItem="$emit('removeItem', item.id)"
                  @thermalPrint="(data) => $emit('thermalPrint', data)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getConsolidationOrdersCommonList.next_page_url !== null && $store.getters.getConsolidationOrders.length > 0"
                @click.native="$emit('showMore')"
                :count="$store.getters.getConsolidationOrdersCommonList.total - $store.getters.getConsolidationOrdersForPage * $store.getters.getConsolidationOrdersCommonList.current_page < $store.getters.getConsolidationOrdersForPage ?
                  $store.getters.getConsolidationOrdersCommonList.total - $store.getters.getConsolidationOrdersForPage * $store.getters.getConsolidationOrdersCommonList.current_page:
                  $store.getters.getConsolidationOrdersForPage"
            />
            <ExportBtn
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>


      <template v-if="$store.getters.getConsolidationOrdersLoading === false && $store.getters.getConsolidationOrders.length === 0">
        <NoResult
            :countFilterParams="countFilterParams"
            :title="$t('common_noDataYet.localization_value.value')"
            :titleWithFilter="$t('common_noResult.localization_value.value')"
            :text="$t('common_noDataYetTxt.localization_value.value')"
            :textWithFilter="$t('common_noResultSeems.localization_value.value')"
            @resetFilter="$emit('resetFilter')"
        />
      </template>
      <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


    </div>
  </div>
</template>

<script>
  // import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ConsolidationOrdersTableMobile from "./ConsolidationOrdersTableMobile/ConsolidationOrdersTableMobile";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ConsolidationOrdersTable",
    components: {
      ValueHelper,
      LinkButton,
      ManagerButton,
      TableUserColumn,
      ShowMore,
      ConsolidationOrdersTableMobile,
      NoResult,
      ExportBtn,
      // ShowMore,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [mixinDetictingMobile, FBMMixinsHelper],

    data(){
      return{
        show1: false,
        show2: false,
        show3: false,
        show4: false,
      }
    }
  }
</script>

<style lang="scss" scoped>

  td:first-child{
    text-align: center;
  }

  .site-table thead th,
  .site-table tbody td{
    padding-right: 40px;
  }

  .rank{
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-repeat: no-repeat;

    &.rank-1{
      background-image: url("../../../../../../../assets/img/common/rank-1.svg");
    }

    &.rank-2{
      background-image: url("../../../../../../../assets/img/common/rank-2.svg");
    }

    &.rank-3{
      background-image: url("../../../../../../../assets/img/common/rank-3.svg");
    }
  }

</style>
