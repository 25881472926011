<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row align-items-center">
        <div class="table-card__item-info">
          #{{item.union_id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  :item="item"
                  :mobile="true"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidationOrders_unionCreated.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.union_created_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidationOrders_shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.union_received_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidationOrders_order.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link class="table-link btn-style" v-if="item.order_id"
                           :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.order_type).name + '/' + item.order_id">
                #{{item.order_id}}
              </router-link>
              <template v-else>—</template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidationOrders_customer.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.order_customer_name"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidationOrders_status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.order_status === 'in_progress'">
                {{$t('fbm_inProgress.localization_value.value')}}
              </template>
              <template v-else-if="item.order_status === 'completed'">
                {{$t('fbm_completed.localization_value.value')}}
              </template>
              <template v-else>—</template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidationOrders_orderShipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.order_delivery_date"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-btn pl-2">
          <div class="table-card__item-btn-i"
               v-if="item.order_id"
          >
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :target="'_blank'"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type).name + '/' + item.order_id"
            />
          </div>
        </div>

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../../mixins/FBMMixins/FBMMixins";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ConsolidationOrdersTableMobile",

    components: {
      ValueHelper,
      LinkButton,
      TableUserColumn
    },

    mixins: [
      FBMMixinsHelper
    ],

    props: {
      item: Object,
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {

    },

  }
</script>

<style lang="scss" scoped>
</style>
