<template>
  <div>
    <ConsolidationOrdersHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <ConsolidationOrdersFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ConsolidationOrdersTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          :navTabs="navTabs"
          :consoUsers="consoUsers"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @removeItem="(id) => { $emit('removeItem', id) }"
          @acceptInMinnesota="(id) => { $emit('acceptInMinnesota', id) }"
          @getConsolidationOrdersProform="(id) => { $emit('getConsolidationOrdersProform', id) }"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


import ConsolidationOrdersHead from "./ConsolidationOrdersHead/ConsolidationOrdersHead";
import ConsolidationOrdersFilter from "./ConsolidationOrdersFilter/ConsolidationOrdersFilter";
import ConsolidationOrdersTable from "./ConsolidationOrdersTable/ConsolidationOrdersTable";

export default {
  name: "ConsolidationOrdersTableUser",

  components: {
    ConsolidationOrdersHead,
    ConsolidationOrdersFilter,
    ConsolidationOrdersTable,
  },

  props: {
    typeShops: String,
    countFilterParams: Number,
    filterGetParams: Object,
    navTabs: Object,
    consoUsers: Array,
  },

  data(){
    return{
      showFilter: false,
      selectedNow: false,
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.filterGetParams = newVal
    },
    countFilterParams(newVal) {
      this.countFilterParams = newVal
    },
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },

    selectAll(val) {
      this.selectedNow = val
    },
  }
}
</script>

<style scoped>

</style>
