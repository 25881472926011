<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate([-->
<!--            'consolidationOrders_Id',-->
<!--            ])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('consolidationOrders_Id.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="orderId"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="table-filter__item"-->
<!--           v-if="isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>-->

<!--        <SearchSelect-->
<!--            :options="options"-->
<!--            :label="$t('common_user.localization_value.value')"-->
<!--            :functionSearch="functionSearch"-->
<!--            @change="changeUsersFilter"-->
<!--            :selected="userName"-->
<!--            :userEmail="true"-->
<!--            :typeSelect="'users'"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['consolidations_created'])"></span>
        <DatePickerDefault
            :label="$t('consolidations_created.localization_value.value')"
            v-model="createdDate"
        >
          <template slot="body">
            <date-picker
                v-model="createdDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate([-->
<!--            'consolidationOrders_customer',-->
<!--            ])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('consolidationOrders_customer.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="contactName"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate([-->
<!--            'consolidationOrders_trackNumber',-->
<!--            ])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('consolidationOrders_trackNumber.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="trackingNumber"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate([-->
<!--            'consolidationOrders_recipientEmail',-->
<!--            ])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('consolidationOrders_recipientEmail.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="recipientEmail"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate([-->
<!--            'common_comment',-->
<!--            ])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('common_comment.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="comment"-->
<!--        />-->
<!--      </div>-->




    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
// import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'

export default {
  name: "ConsolidationFilter",
  components: {
    DatePicker,
    DatePickerDefault,
    // SearchSelect,
    // SearchSelect,
    FilterBlock,
    MainButton,
    // DefaultInput,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  mixins: [filterFunc, usersFunc],

  data(){
    return {
      orderId: this.filterGetParams.orderId ? this.filterGetParams.orderId : '',

      createdDate: this.filterGetParams.createdDate ? this.generateFilterDate(this.filterGetParams.createdDate, 'MM/DD/YY') : '',

      contactName: this.filterGetParams.contactName ? this.filterGetParams.contactName : '',
      trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
      recipientEmail: this.filterGetParams.recipientEmail ? this.filterGetParams.recipientEmail : '',
      comment: this.filterGetParams.comment ? this.filterGetParams.comment : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',


      options: [],

      filterCounts: [
        'orderId',
        'createdDate',
        'contactName',
        'trackingNumber',
        'recipientEmail',
        'userName',
        'userId',
        'comment',
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.orderId = newVal.orderId ? newVal.orderId : ''

      this.createdDate = newVal.createdDate ? this.generateFilterDate(newVal.createdDate, 'MM/DD/YY') : ''

      this.contactName = newVal.contactName ? newVal.contactName : ''
      this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
      this.recipientEmail = newVal.recipientEmail ? newVal.recipientEmail : ''
      this.comment = newVal.comment ? newVal.comment : ''

      this.userId = newVal.userId ? newVal.userId : ''
      this.userName = newVal.userName ? newVal.userName : ''
    },

  },

  mounted() {

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    functionSearch(value, loading) {
      loading(true)
      this.getFilteredUsers(value, this, 'options', loading, true)
    },

    changeUsersFilter(value) {
      this.userId = value.id
      this.userName = value.contacts[0].user_full_name
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.createdDate = this.changeDateParams(this, 'createdDate')

      this.$emit('changeFilter', data)
    },
  },
}
</script>

<style scoped>

</style>
